<template>
	<v-card class="sacrifice-card pb-6">
		<v-card-text v-if="status == null">
			
			<p class="text-center text-title">Sacrifice Tokens</p>
			<p class="text-center text-small-light mb-6">Sacrifice tokens and recieve points</p>
			<v-text-field v-model="data.value" class="number-input" type="number">
                <span class="mt-1 opacity-60" slot="append">MAMOT</span>
			</v-text-field>
			<v-text-field v-model="data.wallet" class="number-input" type="text">

				<span class="mt-1 opacity-60" slot="append">WALLET</span>
			</v-text-field>
			<v-row>
				
			</v-row>
			<v-progress-linear indeterminate color="primary" v-if="loading" class="mt-5" height="25"> Waiting for the 2 transactions to confirm</v-progress-linear>
			<v-btn elevation="0" class="text-dark mb-6 mt-6" :loading="loading" @click="submit()" rounded block color="systemwhite" :disabled="!connected">SACRIFICE TOKENS</v-btn>
			<!-- <v-btn elevation="0" class="text-dark mb-6 mt-6" :loading="loading"  rounded block color="systemwhite" disabled>COMING SOON</v-btn> -->

		</v-card-text>
		<v-card-text v-else-if="status == 'success'">
			<p class="text-center text-title">Transaction succesfull</p>
			<p class="text-center text-small-light mb-6">You have successfully sacrificed your tokens</p>

			<v-row>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="6"><span class="text-offwhite text-small-light">You have recieved:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ points }} Points</p></v-col
				>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="4"><span class="text-offwhite text-small-light">Exchange rate:</span></v-col>
				<v-col cols="8"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ data.value }} $MAMOT = {{ points }} Points</p></v-col
				>
				<v-col cols="12"><span class="text-offwhite text-small-light">TXN: {{txn}}</span></v-col>
			
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
			</v-row>

			<v-btn elevation="0" class="text-dark mb-6 mt-6" rounded block color="systemwhite" to="/">Back</v-btn>
		</v-card-text>
		<v-card-text v-else-if="status == 'failed'">
			<p class="text-center text-title">Transaction failed</p>
			<p class="text-center text-small-light mb-6">The transaction failed to complete</p>

			<v-btn elevation="0" class="text-dark mb-6 mt-6" rounded block color="systemwhite" to="/">Back</v-btn>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	methods: {
		submit() {
			this.$emit("sacrificefor", this.data.value,this.data.wallet);
		},
     
	},
	mounted(){
		this.$emit("reset");
	},
	data: () => ({
		data: {
			value: "0",
            wallet: ""
		},
		rate: 1,
		step: 1,
	}),
	computed: {
		points() {
			return (this.data.value * this.rate);
		}
	},
	props: {
		connected: Boolean,
		loading: Boolean,
		status: String,
		txn: String
	},

}
</script>