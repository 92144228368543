<template>
	<v-card class="sacrifice-card pb-6">
		<v-card-text v-if="status == null">
			<p class="text-center text-title">Withdraw Revenue Share</p>
			<v-row>
				<v-col cols="6"><span class="text-offwhite text-small-light">Your pool balance:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">$ {{ claimbalanceETH }}</p></v-col
				>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-progress-linear indeterminate color="primary" v-if="loading" class="mt-5" height="25"> Waiting for transactions to confirm</v-progress-linear>
			<v-btn elevation="0" class="text-dark mb-6 mt-6" :loading="loading" @click="submit()" rounded block color="systemwhite" :disabled="!connected">CLAIM REWARDS</v-btn>
		</v-card-text>
		<v-card-text v-else-if="status == 'success'">
			<p class="text-center text-title">Transaction succesfull</p>
			<p class="text-center text-small-light mb-6">You have successfully withdrawn your available balance</p>

			<v-row>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="6"><span class="text-offwhite text-small-light">You have recieved:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ claimbalanceETH }} ETH</p></v-col
				>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-btn elevation="0" class="text-dark mb-6 mt-6" rounded block color="systemwhite" to="/">Back</v-btn>
		</v-card-text>
		<v-card-text v-else-if="status == 'failed'">
			<v-btn elevation="0" class="text-dark mb-6 mt-6" rounded block color="systemwhite" to="/">Back</v-btn>
		</v-card-text>
	</v-card>


</template>

<script type="module">



export default {
	methods: {
		submit() {
			this.$emit("claimrewards");
		},
	},
	mounted() {
		this.$emit("reset");
	},
	data: () => ({
		data: {
			value: "0"
		},
		rate: 1,
		step: 1,
	}),
	computed: {

	},
	props: {
		connected: Boolean,
		loading: Boolean,
		status: String,
		txn: String,
		claimbalance: Number,
		claimbalanceETH: Number,
	},



}
</script>